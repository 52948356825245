import "./index.scss";
import {Button, Divider, Spin, Card, Badge, Progress, message} from "antd";
import FundraiserCampaignCorporate from "./Corporate";
import FundraiserCampaignIndividual from "./Individual";
import FundraiserCampaignJunior from "./Junior";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { LinkOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

import riceBoyPng from '../../../assets/images/riceboy.png';

function FundraiserCampaign() {
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { fundraiserStore, donateStore, homeStore } = useStore();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [donationRecord, setDonationRecord] = useState({
    list: [],
    count: 0,
  });
  const [donationRecordSearch, setDonationRecordSearch] = useState({
    limit: 4,
    offset: 0,
  });
    const linkUrl = window.location.origin + "/campaign/" + params.campaignId;

  useEffect(() => {
    homeStore.currentNav = "";
    (async () => {
      try {
        setLoading(true);

        let result = {};
        // 获取campaign
        let campaignData = await fundraiserStore.getCampaignById({
          id: params.campaignId,
          isPublic: true,
        });

        if (campaignData.data) {
          // 获取头像
          let avatarUrl = campaignData.data.avatarAssetId
            ? await fundraiserStore.getAssetUrl({
                id: campaignData.data.avatarAssetId,
              })
            : riceBoyPng;

          // 获取每个素材的图片
          let assetsUrl = [];
          if (campaignData?.data?.assetIds) {
            for (let assetId of campaignData?.data?.assetIds) {
              let url = assetId
                ? await fundraiserStore.getAssetUrl({
                    id: assetId,
                  })
                : null;
              assetsUrl.push(url);
            }
          }

          result = {
            name: campaignData.data.name,
            description: campaignData.data.description,
            donationAmount: campaignData.data.donationAmount === null ? "***" : campaignData.data.donationAmount,
            donorAmount: campaignData.data.donorAmount || "***",
            avatarUrl,
            assets: assetsUrl,
            category: campaignData.data.category,
            voteAmount: campaignData.data.voteAmount,
            quantity: campaignData.data.quantity,
            companyName: campaignData.data.companyName || "",
            videoUrl: campaignData.data.videoUrl,
            targetDonationAmount: campaignData.data.targetDonationAmount,
              sharingText: campaignData.data.sharingText
          };

          setData({ ...result });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error.response?.data === "NOT_FOUND") {
          navigate("/message", {
            state: {
              content: "campaignNotFound",
            },
          });
        }
      }

      // 默认查询
      await getDonationRecord();
    })();
  }, []);

  async function getDonationRecord() {
    let records = await donateStore
      .getDonateRecord({
        campaignId: params.campaignId,
        ...donationRecordSearch,
      })
      .catch(() => {
        return null;
      });
    let list = [];

    if (records && records.data.data.length) {
      for (let item of records.data.data) {
        list.push({
          displayName: item.displayName || "***",
          amount: item.amount || "***",
          donorMessage: item.donorMessage || "***",
          code: item.code,
        });
      }

      setDonationRecord({
        list: list,
        count: records.data.count,
      });
    }
  }

  // 去支付
  const goToDonation = (e) => {
    e.preventDefault();
    navigate("/support-us?campaignId=" + params.campaignId);
  };



  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const cardHtmlFun = function (item) {
    return (
      <Badge
        count={"$" + (item.amount || "***")}
        style={{
          height: isMobile ? "30px" : "50px",
          borderRadius: isMobile ? "30px" : "50px",
          width: isMobile ? "30px" : "50px",
          left: 0,
          margin: 0,
          padding: 0,
          lineHeight: isMobile ? "32px" : "50px",
          background: "#5074ae",
          transform: "translate(0px, 0px)",
          fontSize: isMobile ? "8px" : "12px",

        }}
      >
        <Card
          style={{
            textAlign: "center",
            width: "100%",
            marginBottom: "15px",
          }}
        >
          <img
            alt="Rice Boy"
            src={riceBoyPng}
          />
          <p
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: 0,
            }}
          >
            {item.displayName || "***"}
          </p>

          <Divider
            style={{
              width: "30px",
              minWidth: "auto",
              margin: "auto",
              height: "3px",
              background: "#5074ae",
            }}
          ></Divider>

          <p
            style={{
              margin: "6px",
            }}
          >
            {item.donorMessage || "***"}
          </p>
        </Card>
      </Badge>
    );
  };

  function onClickNextPage() {
    setLoading(true);
    let tmpIndex = donationRecordSearch.limit * 2;
    setDonationRecordSearch({
      ...donationRecordSearch,
      limit: tmpIndex,
    });
  }

  useEffect(() => {
    (async () => {
      await getDonationRecord();
      setLoading(false);
    })();
  }, [donationRecordSearch]);

  return (
    <>
      <div className="fundraiser-campaign">
        <div className="container">
          <h2 className="content-title">
            {data?.category === "CORPORATE"
              ? t("fundraiserCampaignCorporateTitle")
              : null}
            {data?.category === "INDIVIDUAL"
              ? t("fundraiserCampaignIndividualTitle")
              : null}
            {data?.category === "SCHOOL"
              ? t("fundraiserCampaignSchoolTitle")
              : null}
          </h2>
        </div>

        <div className="content-detail">
          <div className="container">
            <Spin spinning={loading}>
              {data ? (
                <>
                  {data?.category === "CORPORATE" ? (
                    <FundraiserCampaignCorporate data={data} />
                  ) : null}
                  {data?.category === "INDIVIDUAL" ? (
                    <FundraiserCampaignIndividual data={data} />
                  ) : null}
                  {data?.category === "SCHOOL" ? (
                    <FundraiserCampaignJunior data={data} />
                  ) : null}


                  {Number(data?.targetDonationAmount) > 0 ? <>
                    <div style={{
                      textAlign: 'center',
                      width: '100%'
                    }}>{t("supportUsTargetAmountTotalTitle") + ": " + (parseInt((data?.donationAmount /
                        Number(data?.targetDonationAmount)) *
                        100) || 0) +"%"}</div>
                    <Progress
                        percent={
                          parseInt((data?.donationAmount /
                              Number(data?.targetDonationAmount)) *
                              100)
                        }
                        showInfo={false}
                        status="active"
                        strokeColor={{
                          from: "#ee1c25",
                          to: "#ee1c25",
                        }}
                        style={{
                          width: '100%',
                          marginBottom:'0'
                        }}
                    />
                    <div style={{
                      textAlign: 'center',
                      width: '100%',
                      marginBottom:'5%'
                    }}>{t("supportUsTargetAmountTitle")+ ": HK$"+
                    Number(data?.targetDonationAmount).toFixed(0)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div></>:null}
                  {data ? (
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        type="primary"
                        shape="round"
                        size="large"
                        danger
                        className="support-button"
                        onClick={(e) => goToDonation(e)}
                        href={"/support-us?campaignId=" + params.campaignId}
                      >
                        {t("btnSupportDonate")}
                      </Button>
                    </div>
                  ) : null}

                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "20px",
                        }}
                    >
                      <FacebookShareButton
                          url={linkUrl}
                          style={{
                            marginLeft: "10px",
                          }}
                      >
                        <FacebookIcon size={32} round />
                      </FacebookShareButton>

                      <CopyToClipboard
                          text={linkUrl}
                          onCopy={() => {
                            message.success("Copied!");
                          }}
                      >
                      <Button icon={<LinkOutlined ></LinkOutlined>}  style={{
                        marginLeft: "10px",
                        outline: 'none',
                        borderRadius: "50%",
                        verticalAlign: "top",
                        border: '1px solid #cccccc',
                        color: "black",
                        background: "#ffffff"
                      }}>
                      </Button>
                      </CopyToClipboard>

                      <WhatsappShareButton
                          url={`${data?.sharingText} \n ${linkUrl}`}
                          style={{
                            marginLeft: "10px",
                          }}
                      >
                        <WhatsappIcon size={32} round />
                      </WhatsappShareButton>







                    {/*<WeiboShareButton*/}
                        {/*url={`${data?.sharingText} \n ${linkUrl}`}*/}
                      {/*style={{*/}
                        {/*display: "inline",*/}
                        {/*marginLeft: "10px",*/}
                      {/*}}*/}
                    {/*>*/}
                      {/*<WeiboIcon size={32} round />*/}
                    {/*</WeiboShareButton>*/}
                    </div>

                  {donationRecord.list.length ? (
                    <div
                      style={{
                        width: "100%",
                        marginTop: 50,
                      }}
                    >
                      <div
                        style={{
                          float: "left",
                          width: "100%",
                        }}
                      >
                        <div className="donation-record-list">
                          {donationRecord.list.map((item, index) => {
                            if (index % 4 === 0) {
                              return cardHtmlFun(item);
                            }
                          })}
                        </div>
                        <div className="donation-record-list">
                          {donationRecord.list.map((item, index) => {
                            if (index % 4 === 1) {
                              return cardHtmlFun(item);
                            }
                          })}
                        </div>
                        <div className="donation-record-list">
                          {donationRecord.list.map((item, index) => {
                            if (index % 4 === 2) {
                              return cardHtmlFun(item);
                            }
                          })}
                        </div>
                        <div className="donation-record-list">
                          {donationRecord.list.map((item, index) => {
                            if (index % 4 === 3) {
                              return cardHtmlFun(item);
                            }
                          })}
                        </div>
                      </div>
                      {donationRecord.list.length < donationRecord.count ? (
                        <div
                          style={{
                            float: "left",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          <Button onClick={onClickNextPage}>
                            {t("campaignDonationRecordLoadMore")}
                          </Button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </>
              ) : (
                <div
                  style={{
                    height: "5em",
                  }}
                ></div>
              )}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
}

export default FundraiserCampaign;
