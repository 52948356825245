import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  message,
  Checkbox,
  Divider,
  Avatar,
  Typography,
  Spin,
  Space,
  Image,
} from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";
import FoodAngelSupportLogo from "../../assets/images/foodangel_logo_support.png";
import TextArea from "antd/es/input/TextArea";
const { Title } = Typography;

function SupportUs() {
  const formRef = useRef(null);
  const { fundraiserStore, loginStore, donateStore, homeStore } = useStore();
  const navigate = new useNavigate();
  const [params] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [avatarData, setAvatarData] = useState();
  const { t, i18n } = useTranslation();
  const [payMethod, setPayMethod] = useState();

  // 是否从注册过来
  const [pathParam, setPathParam] = useState({
    returnUrl: null,
    cancelUrl: null,
    userId: null,
    campaignId: null,
  });

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  // 提交
  async function onFinish(values) {
    try {
      let data = {
        userId: avatarData?.userId
          ? avatarData.userId
          : loginStore.userData?.id,
        campaignId: avatarData?.campaignId || null,
        amount:
          values.donationAmount.type === -1
            ? Number(values.donationAmount.amount)
            : Number(values.donationAmount.type),
        paymentType: values.paymentType,
        type: values.donationType,
        isAmountVisible: values.isAmountVisible !== "true", // 公開金額
        supportUsType: null,
        title: values.title,
        surname: values.surName,
        firstName: values.firstName,
        displayName: values.displayName,
        nameOfCorporation: values.nameOfCorporation,
        email: values.email,
        contactNumber: values.contactNumber,
        receiptChoice: values.receiptInfo.isNeed,
        address: values.address,
        building: values.building,
        street: values.street,
        district: values.district,
        country: values.country,
        donorMessage: values.donorMessage,
        nameOnReceipt:
          values.receiptInfo.isNeed !== "NO" ? values.receiptInfo.name : null,
        isRegister: !!pathParam.isPortal,
        qrCodeImageWidth: "320",
        returnUrl: null,
        cancelUrl: null,
        isReceiveInformation: !values.isReceiveInformation,
        language: i18n.language.replace("_", "-"),
        sandboxEmailAddress: values.sandboxEmailAddress,
        sandboxWalletAddress: values.sandboxWalletAddress,
      };


      // 如果paypal，必填returnUrl和cancelUrl，其他留空
      if (values.paymentType === "PAYPAL") {
        // 从campaign portal过来的
        data.cancelUrl = window.location.origin + "/donation-payment";
        data.returnUrl = window.location.origin + "/donation-payment";
        if (pathParam.campaignId) {
          let campaignUrl = `?target=campaign&campaignId=${pathParam.campaignId}&paymentType=${values.paymentType}`;
          data.returnUrl += campaignUrl;
          data.cancelUrl += campaignUrl;
        } else if (pathParam.isPortal) {
          let protalUrl = `?target=portal&userId=${pathParam.userId}&paymentType=${values.paymentType}`;
          data.returnUrl += protalUrl;
          data.cancelUrl += protalUrl;
        } else {
          data.returnUrl += `?target=support-us&paymentType=${values.paymentType}`;
          data.cancelUrl += `?target=support-us&paymentType=${values.paymentType}`;
        }
      }

      window.scrollTo({ top: 0, behavior: "smooth" });

      setLoading(true);

      let res = await donateStore.donateRecord(data);
      // 支付完成
      if (values.paymentType === "PAYPAL") {
        window.location.href = res.data.link;
        setLoading(false);
      } else {
        let pathStr = "";
        if (pathParam.campaignId) {
          pathStr += `&target=campaign&campaignId=${pathParam.campaignId}`;
        } else if (pathParam.isPortal) {
          pathStr += `&target=portal&userId=${pathParam.userId}`;
        } else {
          pathStr += `&target=support-us`;
        }

        navigate(
          `/donation-payment?donationRecordId=${res.data.donationRecordId}&paymentType=${values.paymentType}${pathStr}`,
          {
            state: {
              paymeUrl: values.paymentType === "PAYME" ? res.data.link : null,
              isMobile,
              confirmationNumber:
                values.paymentType === "CHEQUE" || values.paymentType === "BANK"
                  ? res.data.confirmationNumber
                  : null,
            },
          }
        );

        // 手機版payme, 需跳頁 payme手機版的時候增加一個過度，同時打開app和過度頁
        if (values.paymentType === "PAYME" && isMobile) {
          window.location.href = res.data.mobileLink;
        }
      }
    } catch (err) {
      setLoading(false);
      switch (err.response?.data) {
        case "INTERNAL_SERVER_ERROR": {
          return message.error(t("supportUsErrorMessageInternalServerError"));
        }
      }
      message.error(t("supportUsErrorMessageUnknown"));
    }
  }

  const [minDonationAmount, setMinDonationAmount] = useState(0);
  useEffect(() => {
    (async () => {
      try {
        let res = await donateStore.getPaymentMethod();
        setPayMethod([...res.data.data]);
      } catch (error) {
        console.log(error);
      }

      try {
        let category = null;
        if (loginStore.userData) {
          category = loginStore.userData.category;
        }

        if (category === "INDIVIDUAL") {
          setMinDonationAmount(
            Number(homeStore.systemSetting?.individual_minimum_donation_amount)
          );
        }

        if (category === "CORPORATE") {
          setMinDonationAmount(
            Number(homeStore.systemSetting?.corporate_minimum_donation_amount)
          );
        }

        let campaignId = params.get("campaignId");
        let userId = params.get("userId");
        let isPortal = params.get("isPortal");

        setPathParam({
          campaignId,
          userId,
          isPortal,
        });

        if (campaignId) {
          let res = await fundraiserStore.getCampaignById({
            id: campaignId,
            isPublic: true,
          });

          if (res.data) {
            setAvatarData({
              name: res.data.name,
              avatarUrl: res.data.avatarAssetId
                ? await fundraiserStore.getAssetUrl({
                    id: res.data.avatarAssetId,
                  })
                : null,
              campaignId: res.data.id,
              userId: res.data.userId,
              donationAmountVisible: res.data.donationAmountVisible
            });
          }
        }

        // 直接点击支持 捐给fa
        if (!campaignId && !userId && !isPortal) {
          let res = await fundraiserStore.getCampaign({
            category: "FA",
            isPublic: true,
          });

          if (res.data.data.length) {
            for (let item of res.data.data) {
              setAvatarData({
                name: item.name,
                avatarUrl: FoodAngelSupportLogo,
                campaignId: item.id,
                userId: item.userId,
                type: "FA",
              });
            }
          }
        }
      } catch (error) {
        if (error.response?.data === "NOT_FOUND") {
          navigate("/message", {
            state: {
              content: "campaignNotFound",
            },
          });
        }
      }
    })();
  }, []);

  // 监控条款
  const [form] = Form.useForm();

  // 捐款金额
  const [donationAmount, setDonationAmount] = useState();
  // 是否自定义金额
  function onChangeCustomAmountHandler(e) {
    if (e.target.value !== -1) {
      setDonationAmount(e.target.value);

      // 清除上一次写入的数字
      form.setFieldsValue({
        donationAmount: {
          amount: "",
        },
      });
    }
  }

  const [disabled, setDisabled] = useState(true);

  const onChangePersonalInfomation = () => {
    setDisabled(!disabled);
  };

  const [isReceiptMail, setIsReceiptMail] = useState(false);

  const onValuesChange = (changedValues, allValues) => {
    setIsReceiptMail(allValues.receiptInfo.isNeed === "MAIL");
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="support-us-content">
          <div className="container">
            <h2 className="content-title">{t("menuSupportUs")}</h2>
          </div>

          <div className="content-detail-description">
            <div className="container">
              {avatarData?.avatarUrl ? (
                <div className="avatart-content">
                  <br></br>
                  {avatarData.type !== "FA" ? (
                    <Avatar
                      className="content-avatar"
                      size={{
                        xs: 100,
                        sm: 120,
                        md: 140,
                        lg: 160,
                        xl: 180,
                        xxl: 200,
                      }}
                      src={avatarData?.avatarUrl}
                    ></Avatar>
                  ) : (
                    <Image src={avatarData.avatarUrl} preview={false} />
                  )}

                  <Title>
                    {avatarData?.name[i18n.language.replace("_", "-")]}
                  </Title>
                  <Divider className="divider-line"></Divider>
                </div>
              ) : null}

              <Form
                ref={formRef}
                labelCol={{ span: 6 }}
                layout="horizontal"
                size="large"
                onFinish={onFinish}
                form={form}
                onValuesChange={onValuesChange}
              >
                <Title>{t("supportUsTitleDonationMethod")}</Title>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t("supportUsTitleDonationMethodErrorRequired"),
                    },
                  ]}
                  name="paymentType"
                >
                  <Radio.Group style={{ width: "100%" }}>
                    <Row>
                      {payMethod && payMethod.length
                        ? payMethod.map((item) => {
                            return (
                              <Col xs={12} sm={6}>
                                <Radio
                                  value={item.name.toUpperCase()}
                                  disabled={!item.isEnabled}
                                >
                                  {
                                    item.displayName[
                                      i18n.language.replace("_", "-")
                                    ]
                                  }
                                  {!item.isEnabled ? " (Coming soon)" : null}
                                </Radio>
                              </Col>
                            );
                          })
                        : null}
                    </Row>
                  </Radio.Group>
                </Form.Item>
                <Title>{t("supportUsTitleDonationAmount")}</Title>
                <Form.Item>
                  <Form.Item
                    name={["donationAmount", "type"]}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: t("supportUsTitleDonationAmountErrorRequired"),
                      },
                    ]}
                  >
                    <Radio.Group
                      onChange={onChangeCustomAmountHandler}
                      style={{ width: "100%" }}
                    >
                      <Row align="middle">
                        {pathParam && pathParam.isPortal ? (
                          <Col xs={24} sm={12}>
                            <Radio value={minDonationAmount}>
                              HK$
                              {minDonationAmount}
                              {t("supportUsBasic")}
                            </Radio>
                          </Col>
                        ) : (
                          <>
                            <Col xs={24} sm={12}>
                              <Radio value={600}>
                                HK$600 (30 {t("supportUsCannedFoodUnit")})
                              </Radio>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Radio value={400}>
                                HK$400 (20 {t("supportUsCannedFoodUnit")})
                              </Radio>
                            </Col>
                            <Col xs={24} sm={12}>
                              <Radio value={200}>
                                HK$200 (10 {t("supportUsCannedFoodUnit")})
                              </Radio>
                            </Col>
                          </>
                        )}

                        <Col xs={24} sm={12}>
                          <Radio value={-1}>
                            {t("supportUsDonationAmountOther")} HK${" "}
                          </Radio>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) => {
                              return (
                                prevValues.donationAmount?.type !==
                                currentValues.donationAmount?.type
                              );
                            }}
                          >
                            {({ getFieldValue }) =>
                              getFieldValue("donationAmount") &&
                              getFieldValue("donationAmount")["type"] === -1 ? (
                                <Form.Item
                                  name={["donationAmount", "amount"]}
                                  noStyle
                                  rules={[
                                    {
                                      required: true,
                                      pattern: new RegExp(
                                        /^(([1-9]\d*)|\d)(\.\d{1,2})?$/,
                                        "g"
                                      ),
                                      message: t(
                                        "supportUsDonationAmountOtherAmountErrorRequired"
                                      ),
                                    },
                                    {
                                      type: "number",
                                      min:
                                        pathParam && pathParam.isPortal
                                          ? minDonationAmount
                                          : 0,
                                      message: t("supportUsDonationAmountMin", {
                                        amount:
                                          pathParam && pathParam.isPortal
                                            ? minDonationAmount
                                            : 0,
                                      }),
                                      transform(value) {
                                        if (value) {
                                          return Number(value); //将输入框当中的字符串转换成数字类型
                                        }
                                      },
                                    },
                                  ]}
                                  normalize={(value) => {
                                    setDonationAmount(value);
                                    // 清除上一次写入的数字
                                    if (value < 100) {
                                      form.setFieldsValue({
                                        receiptInfo: {
                                          isNeed: "NO",
                                        },
                                      });
                                    }

                                    return value;
                                  }}
                                >
                                  <Input
                                    style={{
                                      width: 150,
                                    }}
                                  />
                                </Form.Item>
                              ) : null
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                </Form.Item>
                {avatarData?.donationAmountVisible ? <>

                  <Title>{t("supportUsTitleisAmountVisible")}</Title>
                  <Form.Item
                      name="isAmountVisible"
                      rules={[
                        {
                          required: true,
                          message: t("supportUsTitleisAmountVisibleErrorRequired"),
                        },
                      ]}
                  >
                    <Radio.Group>
                      <Radio value={"false"}>
                        {t("supportUsisAmountVisibleFalse")}
                      </Radio>
                      <Radio value={"true"}>
                        {t("supportUsisisAmountVisibleTrue")}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </>:null}

                <Title>{t("supportUsTitleDonationType")}</Title>
                <Form.Item
                  name="donationType"
                  rules={[
                    {
                      required: true,
                      message: t("supportUsTitleDonationTypeErrorRequired"),
                    },
                  ]}
                >
                  <Radio.Group>
                    <Radio value={"INDIVIDUAL"}>
                      {t("supportUsDonationTypeIndividual")}
                    </Radio>
                    <Radio value={"CORPORATE"}>
                      {t("supportUsDonationTypeCorporate")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Title>{t("supportUsDonorMessageTitle")}</Title>
                <Form.Item
                    name={"displayName"}
                    label={t("supportUsInfoDisplayName")}
                    rules={[
                      {
                        type: "string",
                        max: 20,
                        message: t("supportUsFirstNameLength"),
                      },
                    ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                    name={"donorMessage"}
                    label={t("supportUsDonorMessageMessage")}
                    rules={[
                      {
                        type: "string",
                        max: 300,
                        message: t("supportUsDonorMessageLength"),
                      },
                    ]}
                >
                  <TextArea />
                </Form.Item>



                <Title>{t("supportUsTitleDonationInformation")}</Title>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) => {
                    return (
                      prevValues.donationType !== currentValues.donationType
                    );
                  }}
                >
                  {({ getFieldValue }) =>
                    getFieldValue("donationType") === "CORPORATE" ? (
                      <Form.Item
                        name="nameOfCorporation"
                        label={t("supportUsTitleDonationNameOfCorporation")}
                        rules={[
                          {
                            required: true,
                            message: t(
                              "supportUsTitleDonationNameOfCorporationErrorRequired"
                            ),
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    ) : null
                  }
                </Form.Item>
                <Form.Item name={"title"} label={t("supportUsInfoTitle")}>
                  <Radio.Group>
                    <Radio value="MR">{t("supportUsInfoTitleMr")}</Radio>
                    <Radio value="MISS">{t("supportUsInfoTitleMiss")}</Radio>
                    <Radio value="MRS">{t("supportUsInfoTitleMrs")}</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name={"firstName"}
                  label={t("supportUsInfoEnglishFirstName")}
                  rules={[
                    {
                      type: "string",
                      max: 20,
                      message: t("supportUsFirstNameLength"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name={"surName"}
                  label={t("supportUsInfoEnglishSurname")}
                  rules={[
                    {
                      type: "string",
                      max: 20,
                      message: t("supportUsFirstNameLength"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>


                <Form.Item
                  name={"email"}
                  label={t("supportUsInfoEmail")}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: t("supportUsInfoEmailErrorRequired"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"contactNumber"}
                  label={t("supportUsInfoContactNumber")}
                  rules={[
                    {
                      required: true,
                      max: 8,
                      min: 8,
                      message: t("supportUsInfoContactNumberErrorRequired"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"address"}
                  label={t("supportUsInfoAddress")}
                  rules={
                    isReceiptMail
                      ? [
                          {
                            required: true,
                            message: t("supportUsInfoAddressErrorRequired"),
                          },
                        ]
                      : []
                  }
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"building"}
                  label={t("supportUsInfoAddressBuilding")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"street"}
                  label={t("supportUsInfoAddressStreet")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"district"}
                  label={t("supportUsInfoAddressDistrict")}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name={"country"}
                  label={t("supportUsInfoAddressCountry")}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                    name={"sandboxEmailAddress"}
                    label={t("supportUsInfoAddressSandboxEmail")}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                    name={"sandboxWalletAddress"}
                    label={t("supportUsInfoAddressSandboxWallet")}
                >
                  <Input />
                </Form.Item>

                <Title>{t("supportUsTitleReceiptChoice")}</Title>
                <Form.Item
                  required
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <Form.Item
                    name={["receiptInfo", "isNeed"]}
                    initialValue={"NO"}
                  >
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="NO">
                          {t("supportUsReceiptChoiceNo")}
                        </Radio>
                        {donationAmount >= 100 ? (
                          <>
                            <Radio value="EMAIL">
                              {t("supportUsReceiptChoiceEmail")}
                            </Radio>
                            <Radio value="MAIL">
                              {t("supportUsReceiptChoiceMail")}
                            </Radio>
                          </>
                        ) : null}
                      </Space>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => {
                      return (
                        prevValues.receiptInfo?.isNeed !==
                        currentValues.receiptInfo?.isNeed
                      );
                    }}
                  >
                    {({ getFieldValue }) =>
                      getFieldValue("receiptInfo") &&
                      getFieldValue("receiptInfo")["isNeed"] !== "NO" ? (
                        <Form.Item
                          name={["receiptInfo", "name"]}
                          label={t("supportUsReceiptChoiceReceiptName")}
                          rules={[
                            {
                              required: true,
                              message: t(
                                "supportUsReceiptChoiceReceiptNameErrorRequired"
                              ),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Title level={5}>{t("supportUsBottomTCTitle")}</Title>
                  <Typography.Paragraph
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    {homeStore.systemSetting?.data_collection_content &&
                      homeStore.systemSetting?.data_collection_content[
                        i18n.language.replace("_", "-")
                      ]}
                  </Typography.Paragraph>
                </Form.Item>


                <Form.Item

                >
                  <Typography.Paragraph
                      style={{
                        fontSize: "12px",
                      }}
                  >
                    {t("supportUsInfomationTcTitleMessage")}
                  </Typography.Paragraph>

                </Form.Item>

                <Form.Item
                  required
                  name="personalInfomation"
                  valuePropName="checked"
                  style={{
                    marginBottom: 0,
                  }}
                  onChange={onChangePersonalInfomation}
                >
                  <Checkbox>
                    <span
                      style={{
                        color: "#ee1c25",
                      }}
                    >
                      *
                    </span>{" "}
                    {t("supportUsBottomTCCheckbox")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  required
                  name="isReceiveInformation"
                  valuePropName="checked"
                >
                  <Checkbox>{t("supportUsBottomNewsCheckbox")}</Checkbox>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    danger
                    htmlType="submit"
                    disabled={disabled}
                    className="wide-button"
                  >
                    {t("btnNext")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
}
export default observer(SupportUs);
