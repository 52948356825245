import "./index.scss";
import { Divider, Typography, Image, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
function DefaultMega() {
  const navigate = new useNavigate();
  const { fundraiserStore, homeStore } = useStore();
  const [data, setData] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    (async () => {
      let res = await fundraiserStore.getCampaign({
        category: "FA",
        isPublic: false,
      });

      if (res.data.data.length) {
        let item = res.data.data[0];
        let url = item.avatarAssetId
          ? await fundraiserStore.getAssetUrl({
              id: item.avatarAssetId,
            })
          : null;
        let precent = Number(homeStore.systemSetting?.fa_target_amount) ? (
          ((item.donationAmount || 0) /
              Number(homeStore.systemSetting?.fa_target_amount)) *
          100
        ).toFixed(0) : null;

        setData({
          url,
          description: item.description,
          precent,
        });
      }
    })();
  }, []);

  // 自适应图片高度百分比
  const imgRef = useRef(null);
  const [offHeight, setOffHeight] = useState();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if(imgRef.current && data?.precent) {

      let currentHeight =
          imgRef.current.offsetHeight * (1 - Number(data?.precent) / 100) || 0;
      setHeight(currentHeight);
    }
  }, [offHeight]);

  function handleWindowSizeChange() {
    setOffHeight(imgRef.current.offsetHeight);
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  return (
      <div className="container">

        {data?.precent ? <div className="mega-content">
          <div className="content-title">
            <Typography.Title>
              {(homeStore.systemSetting?.main_character_title_up &&
                  homeStore.systemSetting?.main_character_title_up[
                      i18n.language.replace("_", "-")
                      ]) ||
              ""}
            </Typography.Title>
            <Typography.Title
                style={{
                  color: "#9d9d9d",
                }}
            >
              {(homeStore.systemSetting?.main_character_title_down &&
                  homeStore.systemSetting?.main_character_title_down[
                      i18n.language.replace("_", "-")
                      ]) ||
              ""}
            </Typography.Title>
          </div>
          <div className="content-detail">
            <Row gutter={16} align="middle">
              <Col xs={12} sm={14} style={{ textAlign: "right" }}>
                <div
                    className="content-detail-image-shadow"
                    style={{
                      height: height,
                    }}
                ></div>
                <div ref={imgRef}>
                  <Image
                      src={data?.url}
                      style={{ maxHeight: 500 }}
                      preview={false}
                      onLoad={handleWindowSizeChange}
                  />
                </div>
              </Col>
              <Col
                  xs={12}
                  sm={10}
                  style={{
                    marginTop: isMobile ? "10px" : 0,
                  }}
              >
                <h2
                    style={{
                      marginBottom: isMobile ? 0 : "17.43px",
                    }}
                >
                  {data?.description[i18n.language.replace("_", "-")]}
                </h2>

                <Typography.Paragraph
                    className="progress-percent"
                    style={{
                      fontSize: "4.5em",
                      fontWeight: "normal",
                      marginBottom: isMobile ? 0 : ".2em",
                    }}
                >
                  {data?.precent}%
                </Typography.Paragraph>

                <Button
                    type="primary"
                    shape="round"
                    style={{
                      borderRadius: "50%",
                      width: "4em",
                      height: "4em",
                      fontWeight: "bold",
                      fontSize: isMobile ? "small" : "large",
                      paddingLeft: 0,
                      paddingRight: 0,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      navigate("/support-us");
                    }}
                >
                  {t("homeCampaignBtnBackIt")}
                </Button>
              </Col>
            </Row>
          </div>

          <div
              style={{
                textAlign: "center",
              }}
          >


            <h3 className="content-bottom">{t("megaDetailPriceTopMessage", {
              amount: i18n.language === 'en' ? Number(homeStore.systemSetting?.fa_target_amount) / 1000000 : Number(homeStore.systemSetting?.fa_target_amount) / 10000,
            })}</h3>
          </div>

          <Divider className="divider-line"></Divider>
        </div>:null}
      </div>

  );
}
export default observer(DefaultMega);
