import "./index.scss";
import { Layout, Pagination, Row, Col } from "antd";
import OurFundraiserComponent from "../../../components/OurFundraiserComponent";
import { useStore } from "../../../store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import riceBoyPng from '../../../assets/images/riceboy.png';

function DefaultOurFoundraiserCorporate() {
  const { fundraiserStore } = useStore();
  const { t, i18n } = useTranslation();
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState({
    offset: 0,
    limit: 3,
    sort: "createdAt",
    category: "CORPORATE",
    isPublic: true,
  });

  const [currentPage, setCurrentPage] = useState(1);
  // 分页按钮
  const paginationOnChange = (page, pageSize) => {
    setCurrentPage(page);
    setSearch({
      ...search,
      offset: (page - 1) * search.limit,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        let res = await fundraiserStore.getCampaign(search);
        if (res.data) {
          let result = [];
          for (let item of res.data.data) {
            let obj = {
              id: item.id,
              name: item.name ? item.name : null,
              description: item.description ? item.description : null,
              avatarSrc: item.avatarAssetId
                ? await fundraiserStore.getAssetUrl({
                    id: item.avatarAssetId,
                  })
                : riceBoyPng,
              type: "CORPORATE",
            };
            result.push(obj);
          }

          setData(result);
          setTotalCount(res.data.count);
        }
      } catch (error) {}
    })();
  }, [search]);

  return (
    <div className="container">
      {data.length ? (
        <div className="our-foundraiser-corporate-content">
          <div className="content-title">
            <h3 className="content-title-first">
              {t("homeOurFundraiserTitle")}
            </h3>
            <h3 className="content-title-second">
              {t("homeOurFundraiserCorporateCaption")}
            </h3>
          </div>
          <Row gutter={[16, 16]}>
            {data.map((item, index) => {
              return (
                <Col key={index} xs={24} sm={8}>
                  <OurFundraiserComponent props={item} />
                </Col>
              );
            })}
          </Row>

          <div className="our-foundraiser-pagination">
            <Pagination
              current={currentPage}
              total={totalCount}
              onChange={paginationOnChange}
              pageSize={search.limit}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default DefaultOurFoundraiserCorporate;
